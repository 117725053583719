<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <h1 class="mb-3 text-center">Register</h1>

        <v-card class="pa-6">
          <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
            <v-alert
              v-model="showSuccess"
              dense
              outlined
              dismissible
              type="success"
            >
              You have successfully registered! Check your email for a verficiation link.
            </v-alert>

            <v-text-field
              v-model="form.email"
              :rules="rules.email"
              label="Email"
              name="username"
            ></v-text-field>
            
            <v-text-field
              v-model="form.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.password"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              name="password"
              counter
              @click:append="showPassword = !showPassword"
            ></v-text-field>

            <v-text-field
              v-model="form.confirmPassword"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="rules.confirmPassword"
              :type="showConfirmPassword ? 'text' : 'password'"
              label="Confirm Password"
              name="confirm_password"
              counter
              @click:append="showConfirmPassword = !showConfirmPassword"
            ></v-text-field>

            <v-alert
              v-model="showError"
              dense
              outlined
              dismissible
              type="error"
            >
              {{errorMessage}}
            </v-alert>

            <v-btn
              type="submit"
              color="primary"
              class="mr-2"
              :disabled="!valid"
              :loading="submitting"
            >Register</v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { EmailRegex } from '@/constants.js'

export default {
  title: 'Register',

  data: function() {
    return ({
      valid: false,
      form: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        email: [
          v => !!v || 'Email is required',
          v => EmailRegex.test(v) || 'Email must be valid',
        ],
        password: [
          v => !!v || 'Password is required',
          v => v.length >= 8 || 'Password must be at least 8 characters'
        ],
        confirmPassword: [
          v => !!v || 'Password confirmation is required',
          v => v === this.form.password || 'Password confirmation must match password'
        ]
      },
      showPassword: false,
      showConfirmPassword: false,
      showError: false,
      errorMessage: '',
      showSuccess: false
    })
  },

  computed: {
    ...mapGetters({
      submitting: 'auth/submitting'
    })
  },

  methods: {
    ...mapActions({
      register: 'auth/register'
    }),
    onSubmit: function() {
      if (!this.submitting) {
        this.errorMessage = ''
        this.showError = false
        this.showSuccess = false

        this.register(this.form)
          .then(() => {
            this.$refs.form.reset()
            this.showSuccess = true
          })
          .catch(error => {
            this.errorMessage = error.message
            this.showError = true
          })
      }
    }
  }
}
</script>
